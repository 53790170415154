import { Container } from 'components';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useCookieBotDeclarationScript } from 'shared/hooks';
import { staticStyles } from 'styles';

const Kolacici: React.FC = () => {
  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const { handleSetElementToAppend } = useCookieBotDeclarationScript(
    `https://consent.cookiebot.com/${process.env.GATSBY_COOKIEBOT_ID}/cd.js`,
  );

  useEffect(() => {
    if (!ref.current) return;
    handleSetElementToAppend(ref.current);
  }, [handleSetElementToAppend]);

  return (
    <>
      <Helmet
        title={`Wienerberger - ${formatMessage({ id: 'cookies.title' })}`}
      />
      <Container className={staticStyles.staticWrapper}>
        <h1 css={staticStyles.title}>
          <FormattedMessage id="cookies.title" />
        </h1>
        <div ref={ref} />
      </Container>
    </>
  );
};

export default Kolacici;
