import { useEffect, useState } from 'react';

export type Status = 'idle' | 'loading' | 'ready' | 'error';
export type ScriptElement = HTMLScriptElement | null;

export function useCookieBotDeclarationScript(src: string) {
  const [divElement, setDivElement] = useState<HTMLDivElement | null>(null);

  function handleSetElementToAppend(refCurrent: HTMLDivElement) {
    setDivElement(refCurrent);
  }

  useEffect(() => {
    const script: ScriptElement = document.createElement('script');
    script.src = src;
    script.async = true;
    script.setAttribute('data-status', 'loading');
    divElement?.appendChild(script);

    return () => {
      if (!script) return;
      const cookieDeclaration = document.querySelector('.CookieDeclaration');
      const cookieDeclarationScript =
        document.querySelector('#CookieDeclaration');

      script.remove();
      cookieDeclaration && cookieDeclaration.parentElement?.remove();
      cookieDeclarationScript && cookieDeclarationScript.remove();
    };
  }, [src, divElement]);

  return { handleSetElementToAppend };
}
